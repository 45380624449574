import Layout from "../component/template/Layout";
import GroupsList from "../component/group/list/index";

function Groups() {
  return (
    <Layout>
      <GroupsList />
    </Layout>
  );
}

export default Groups;
